@import "normalize.css";

body {
  display: flex;
  justify-content: center;

  font-size: 16px;
}

.main {
  display: flex;
  flex-direction: column;
}

@media (min-width: 36em) {
  .main {
    max-width: 36em;
  }
}

code {
  display: block;
  padding: 1em;

  background-color: #eee;
}

footer {
  margin: 1rem 0;

  text-align: right;
}

footer p {
  margin: 0;
}
